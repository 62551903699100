import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'error-params',
  standalone: true,
  imports: [],
  template: `
    <span class="material-symbols-outlined"> warning </span>
    <p>
      Los parámetros leídos del código QR no son válidos o no existen. Por
      favor, verifica el código QR e intenta de nuevo.
    </p>
  `,
  styles: `
    :host {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 8rem;
      color: var(--error-color);
    }
    p {
      text-align: center;
      width: 80%;
      max-width: 620px;
      line-height: 1.3;
    }

    @keyframes blink {
      0% {opacity: 1;}
      50% {opacity: 0;}
      100% {opacity: 1;}
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorParamsComponent {}
