import { Injectable, inject } from '@angular/core';
import {
  APISECURED,
  BASEURL,
  CHECKACTIVATEPATH,
  ORDERPATH,
} from '../models/api.model';
import { HttpClient } from '@angular/common/http';
import { qrRequestValidateParams } from '../models/qr.model';

@Injectable({
  providedIn: 'root',
})
export class QrValidService {
  checkValidateURL = `${BASEURL}/${ORDERPATH}/${APISECURED}/${ORDERPATH}/${CHECKACTIVATEPATH}`;
  http = inject(HttpClient);

  constructor() {}

  checkActivateUrlOrder(requestParams: qrRequestValidateParams) {
    return this.http.post(this.checkValidateURL, requestParams)
  }

}

