import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  NgxScannerQrcodeModule,
} from 'ngx-scanner-qrcode';
import { JsonPipe, AsyncPipe } from '@angular/common';
import { StateAppStore } from '../../store/state-app.store';

@Component({
  selector: 'scanner',
  standalone: true,
  imports: [NgxScannerQrcodeModule, JsonPipe, AsyncPipe],
  templateUrl: './scanner.component.html',
  styleUrl: './scanner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerComponent implements AfterViewInit {
  storeApp = inject(StateAppStore);
  label = signal('start');
  devices: any[] = [];
  @ViewChild('action') actionComp!: NgxScannerQrcodeComponent;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.actionComp.devices.subscribe((devices: any[]) => {
      this.devices = devices;
      this.cdr.detectChanges();
    });
  }

  handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      const device = devices.find((f) =>
        /back|rear|arrière|hinteren|trasera|traseira|environment/gi.test(f.label)
      ); 
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn === 'start') {
      this.label.set('stop');
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      this.label.set('start');
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

  onRead(data: any): void {
    try {
      const value = data[0].value;

      if (!value) {
        this.storeApp.setErrorReader();
        return;
      }

      const paramsReaded = this.extractOriginAndNumeroMesa(value);
      if (!paramsReaded.origin || !paramsReaded.originNumber) {
        this.storeApp.setErrorReader();
        return;
      }

      const paramsRequestStored = this.storeApp.paramsRequest();
      const paramsRequest = { ...paramsReaded, ...paramsRequestStored };
      this.storeApp.qrReaded(paramsRequest);
    } catch (error) {
      this.storeApp.setErrorReader();
    }
  }

  extractOriginAndNumeroMesa(value: string) {
    const valueSplited = value.split('&');
    if (valueSplited.length < 2) {
      return {};
    }
    return {
      origin: valueSplited[0],
      originNumber: valueSplited[valueSplited.length - 1],
    };
  }
}
