import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StateAppStore } from './store/state-app.store';
import { BarComponent } from './components/bar/bar.component';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, BarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  store = inject(StateAppStore);
}
