import { Routes } from '@angular/router';
import { ValidateQrComponent } from './features/validate-qr/validate-qr.component';
import { validateQrResolver } from './resolver/validate-qr.resolver';

export const routes: Routes = [
    {
        path: 'validate-qr',
        component: ValidateQrComponent,
        resolve: {
            params: validateQrResolver
        }
    },
    {
        path: '**',
        redirectTo: 'validate-qr'
    }
];
