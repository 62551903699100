import { ChangeDetectionStrategy, Component, Input, OnInit, inject, signal } from '@angular/core';
import { ScannerComponent } from '../../components/scanner/scanner.component';
import { ErrorParamsComponent } from '../../components/error-params/error-params.component';
import { ActivatedRoute } from '@angular/router';
import { StateAppStore } from '../../store/state-app.store';
import { Errors } from '../../models/state-app.model';
import { LoaderComponent } from '../../components/loader/loader.component';
@Component({
  selector: 'validate-qr',
  standalone: true,
  imports: [ScannerComponent, ErrorParamsComponent, LoaderComponent],
  templateUrl: './validate-qr.component.html',
  styleUrl: './validate-qr.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidateQrComponent implements OnInit {

  stateApp = inject(StateAppStore);
  errorParams = Errors.ERROR_PARAMS;
 
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const resultOfResolve = this.route.snapshot.data['params'];

    if(resultOfResolve?.params) {
      this.stateApp.setParamsRequest(resultOfResolve.params);
    }
  }

  reScan() {
    this.stateApp.resetState();
  }



}
