import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { Errors, StateApp } from '../models/state-app.model';
import { QrValidService } from '../services/qr-valid.service';
import { inject } from '@angular/core';
import { firstValueFrom, from } from 'rxjs';


const initialState: StateApp = {
    dataReaded: null,
    noParams: null,
    errorResponse: null,
    errorReader: false,
    dataResponse: null,
    paramsRequest: null,
    loading: false
}

export const StateAppStore = signalStore(
    {providedIn: 'root'},
    withState(initialState),
    withMethods( (store, qrValidService = inject(QrValidService)) => ({
        noParamsError() {
            patchState(store, {noParams: Errors.ERROR_PARAMS, loading: false, dataReaded: null, errorResponse: null, dataResponse: null, paramsRequest: null, errorReader: false});
        },

        setParamsRequest(params: any) {
                patchState(store, {paramsRequest: params});
        },

        resetState() {
                patchState(store, { dataResponse: null, dataReaded: null, errorResponse: null, errorReader: false });
        },

        setErrorReader() {
                patchState(store, { errorReader: true });
        },

        async qrReaded(data: any) {
                patchState(store, {dataReaded: data, loading: true});
                try {
                        const response = await firstValueFrom(qrValidService.checkActivateUrlOrder(data));
                        patchState(store, {dataResponse: {ok: true}, loading: false});
                    } catch (error) {
                        patchState(store, {errorResponse: error, loading: false});
                    }
        }
    })
));

