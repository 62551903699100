import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bar',
  standalone: true,
  imports: [],
  template: `
    <div class="navbar">
      <h1>
        <img src="assets/logo.png" alt="Logo Timechef">
        <span>
          Timechef
        </span>
      </h1>
    </div>
  `,
  styles: `
    :host {
      display: block;
      height: 75px;
    }

    .navbar {
      height: 100%;
      background-color: #49BA7F;
      h1 {
        height: 100%;
        display: flex;
        padding: 0 1rem;
        justify-content: space-between;
        align-items: center;
      }
      img {
        height: 60px;
      }
      span {
        font-weight: 600;
        font-size: 1.5rem;
        color: #fff;
        flex: 1;
        text-align: left;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarComponent {

}
