@if(!storeApp.dataReaded()) {
<div class="scanner-container">
  @if(action.devices.value.length){
  <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
    <option [value]="null" selected>Select device</option>
    @for (c of devices; track c.deviceId; let i = $index) {
      <option  [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
    }
  </select>
  }

  <ngx-scanner-qrcode
    (event)="onRead($event)"
    #action="scanner"
  ></ngx-scanner-qrcode>
</div>

<button
  class="button button--block"
  (click)="handle(action, action.isStart ? 'stop' : 'start')"
>
  <span class="material-symbols-outlined"> qr_code_scanner </span>
  {{ label() === 'stop' ? "Stop" : "Activar pedido" }}
</button>


}

