@if(stateApp.noParams()) {
    <error-params></error-params>
}



@else if (stateApp.dataReaded() && !stateApp.dataResponse() && !stateApp.errorResponse() && !stateApp.errorReader()) {
    <div class="data-readed__container">
        <span class="material-symbols-outlined symbol">
            fact_check
        </span>
        <p class="data-readed__paragraph">
            Comprobando información...
        </p>
    </div>
}

@else if(stateApp.errorReader()) {
    <div class="data-error__container">
        <span class="material-symbols-outlined icon">
            chat_error
        </span>
        <p class="error-message">
            <span class="error-message">
                Error de lectura, o QR incorrecto, por favor vuelva a escanear el código QR
            </span>
        </p>
        <button class="button button--block reset-button" (click)="reScan()">
            <span class="material-symbols-outlined"> qr_code_scanner </span>
            Volver a escanear
        </button>
    </div>

}

@else if(stateApp.errorResponse()) {
    <div class="data-error__container">
        <span class="material-symbols-outlined icon">
        chat_error
        </span>
        <p class="error-message">
            <span class="error-status">
              Error  {{stateApp.errorResponse().status}} 
            </span>
            @if(stateApp.errorResponse().error.errors.length) {
                @for(error of stateApp.errorResponse().error.errors; track error.Message) {
                    <span class="error-message">
                        {{error.errorMessage}}
                    </span>
                }
            }
        </p>
           @if(stateApp.errorResponse().status === 409) {
               <button class="button button--block reset-button" (click)="reScan()">
                   <span class="material-symbols-outlined"> qr_code_scanner </span>
                   Volver a activar
               </button>
           }
        </div>
    }

@else if(stateApp.dataResponse()) {
    <div class="data-success__container">
        <span class="material-symbols-outlined icon">
            check_circle
        </span>
        <p>
            Pedido activado con éxito.
        </p>    
    </div>
}

@else {
    <div class="data-scanner__container">
        <div class="data-scanner__content">
            <span class="material-symbols-outlined icon">
            qr_code_scanner
            </span>
            <span>
                Escanee el código QR de una mesa para finalizar el pedido
            </span>
        </div>
        <scanner/>
    </div>
}

@if(stateApp.loading()) {
    <div class="loader-container">
        <loader/>
    </div>
}


