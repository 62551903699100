import { qrRequestValidateParams } from './qr.model';

export type StateApp = {
    dataReaded: any;
    errorResponse: any;
    errorReader: boolean;
    noParams: Errors.ERROR_PARAMS | null;
    dataResponse: any;
    paramsRequest: qrRequestValidateParams | null;
    loading: boolean;
}

export enum Errors {
    ERROR_PARAMS = 'error-params',
    ERROR_REQUEST = 'error-request',
    ERROR_RESPONSE = 'error-response'
}