import { ResolveFn } from '@angular/router';
import { StateAppStore } from '../store/state-app.store';
import { inject } from '@angular/core';

export const validateQrResolver: ResolveFn<any> = (
  route, 
  state,
  stateApp = inject(StateAppStore),
  ) => {
  const queryParams = route.queryParamMap;

  const orderId = queryParams.get('orderId');
  const deliveryDate = queryParams.get('deliveryDate');
  const serviceLocationId = queryParams.get('serviceLocationId');
  const serviceId = queryParams.get('serviceId');
  const hash = queryParams.get('hash');

  if(!orderId || !deliveryDate || !serviceLocationId || !serviceId || !hash) return stateApp.noParamsError();

  if(typeof orderId !== 'string' || typeof deliveryDate !== 'string' || typeof serviceLocationId !== 'string' || typeof serviceId !== 'string' || typeof hash !== 'string') return stateApp.noParamsError();


  return queryParams;
};
